/* eslint-disable */
const packages: Array<{
  id: number,
  name: string,
  route: string,
  versions: object
}> = [
  {
    id: 1,
    name: "Imunify360",
    route: "imunify360",
    versions: {}
  },
  {
    id: 2,
    name: "ImunifyAV/AV+",
    route: "imunify-av",
    versions: {}
  },
  {
    id: 3,
    name: "Imunify WAF Rules",
    route: "waf-rules",
    versions: {}
  },
  {
    id: 4,
    name: "Imunify OSSEC Rules",
    route: "ossec-rules",
    versions: {}
  },
  {
    id: 6,
    name: "Imunify Email",
    route: "imunify-email",
    versions: {}
  }
];

export default packages;
