
import { Component, Vue, Watch } from "vue-property-decorator";
import {
  VExpansionPanels,
  VExpansionPanel,
  VExpansionPanelHeader,
  VExpansionPanelContent,
  VTooltip,
  VIcon,
  VBtn
} from "vuetify/lib";
import VersionsModule from "../store/modules/versions";
import { mdiContentCopy } from "@mdi/js";
import { Version } from "@/store/models/Versions";
import SpinnerCustom from "@/components/SpinnerCustom.vue";

@Component({
  components: {
    SpinnerCustom,
    VExpansionPanels,
    VExpansionPanel,
    VExpansionPanelHeader,
    VExpansionPanelContent,
    VTooltip,
    VIcon,
    VBtn
  }
})
export default class Changelog extends Vue {
  private loading: boolean = false;

  @VersionsModule.namespace.Action("getVersions")
  private loadVersions: VersionsModule["getVersions"];

  @VersionsModule.namespace.State("versions") private versions: Version[];

  private panel: number[] = [];
  private snackbar: boolean = false;
  private mdiContentCopy = mdiContentCopy;
  private count: number = 0;

  private copyVal(textToCopy) {
    if (navigator.clipboard && window.isSecureContext) {
      return navigator.clipboard.writeText(textToCopy);
    } else {
      const textArea = document.createElement("textarea");
      textArea.value = textToCopy;
      textArea.style.position = "fixed";
      textArea.style.left = "-999999px";
      textArea.style.top = "-999999px";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      return new Promise<void>((res, rej) => {
        document.execCommand("copy") ? res() : rej();
        textArea.remove();
      });
    }
  }

  private copy(event, text) {
    event.stopPropagation();
    this.copyVal(`${text}`);
    this.snackbar = true;
  }

  private async loadMore() {
    if (this.count > this.versions.length) {
      await this.loadVersions({
        type: this.$route.params.package,
        offset: this.versions[this.versions.length - 1].id,
        clear: false
      });
    }
  }

  @Watch("$route")
  private async paramChanged() {
    this.loading = true;
    const data = await this.loadVersions({
      type: this.$route.params.package,
      offset: 0,
      clear: true
    });
    this.count = data.count;
    this.panel = []
    this.loading = false;
  }

  private async mounted() {
    this.loading = true;
    if (this.$route.params.package) {
      const data = await this.loadVersions({
        type: this.$route.params.package,
        offset: 0,
        clear: true
      });
      this.count = data.count;
    }
    this.panel = []
    if (this.$route.hash){
      this.panel = this.versions.reduce((result: number[], value, index) => {
          if (value.id.includes(this.$route.hash.replace(/#/g, ''))) {
            result.push(index);
          }
          return result;
        }, []);
    }
    this.loading = false;
  }
}
