import Vue from "vue";
import Vuex from "vuex";
import versions from "./modules/versions";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    versions
  }
});
