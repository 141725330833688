<template>
  <div class="spinner-block">
    <img
      class="blink"
      src="./../assets/spinner.png"
      height="121"
    >
  </div>
</template>

<script>
export default {
  name: "SpinnerCustom"
};
</script>

<style scoped lang="scss">
.spinner-block {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.blink {
  display: block;
  animation-name: blinker;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(1, 0, 0, 1);
  animation-duration: 2s;
  -webkit-animation-name: blinker;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: cubic-bezier(1, 0, 0, 1);
  -webkit-animation-duration: 2s;
}

@keyframes blinker {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@-webkit-keyframes blinker {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
</style>
