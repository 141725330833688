
import { Vue, Component } from "vue-property-decorator";
import { VTab, VTabs } from "vuetify/lib";
import packages from "./../utils/variables";

@Component({
  components: {
    VTab,
    VTabs,
  },
})
export default class Navigation extends Vue {
  private packages: Array<object> = packages;
}
