import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import Changelog from "../views/Changelog.vue";
import packages from "@/utils/variables";

Vue.use(VueRouter);

const availableRoutes = packages.map((el) => el.route);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
    children: [
      {
        path: "/:package",
        components: { version: Changelog },
        name: "changelog",
        props: { version: true },
        alias: "/:package/",
        beforeEnter: (to, from, next) => {
          return availableRoutes.includes(to.params.package)
            ? next()
            : next({ name: "changelog", params: { package: "imunify360" } });
        },
      },
      {
        path: "/:package(.*)*",
        redirect: (to) => {
          if (availableRoutes.includes(to.params.package[0])) {
            return {
              name: "changelog",
              params: { package: to.params.package[0] },
            };
          } else {
            return {
              name: "changelog",
              params: { package: "imunify360" },
            };
          }
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
