<template>
  <div>
    <div class="footer">
      <div>
        <a href="https://cloudlinux.com"><img
          src="/we-are-cloudlinux.svg"
          alt="We are Cloudlinux"
          class="d-block"
        ></a>
      </div>
      <div class="footer-company-title">
        © 2021 - {{ new Date().getFullYear() }} All rights reserved. CloudLinux Inc.
      </div>
      <div>
        <div>
          <a
            href="https://cloudlinux.zendesk.com/hc/en-us/requests/new"
            target="_blank"
          >Contact Imunify Support</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterCustom"
};
</script>

<style scoped>
.footer a {
  display: inline-block;
  margin-left: 10px;
  color: #2b98f0 !important;
  font-size: .8rem;
  font-weight: 500;
}
.footer {
  color: #314659;
  text-align: center;
  border-top: 1px solid #e8e8e8;
  height: 102px;
  background: #fff;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: center;
}
.footer div {
  margin: 5px;
  display: inline-flex;
}
.footer-company-title {
  font-size: .8rem;
  color: #d8d8d8;
}
</style>
