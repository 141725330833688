
import { Component, Vue } from "vue-property-decorator";
import Navigation from "@/components/Navigation.vue";
import FooterCustom from "@/components/FooterCustom.vue";

@Component({
  components: {
    Navigation,
    FooterCustom
  }
})
export default class Home extends Vue {
  public search: string = "";

  public scrollTop(): void {
    window.scrollTo(0, 0);
  }
  private async mounted() {
    if (!this.$route.params.package) {
      this.$router.push("/imunify360");
    }
  }
  private clear(): void {
    this.search = "";
  }
}
