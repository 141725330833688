/* eslint-disable */
import { Module, VuexModule, Action, Mutation } from "vuex-module-decorators";
import { Version } from "@/store/models/Versions";
import axios from "axios";
import { BindingHelpers } from "vuex-class/lib/bindings";
import { namespace } from "vuex-class";

export const MODULE_NAME = "versions";
export const getExamNamespace = (): BindingHelpers => namespace(MODULE_NAME);
@Module({ stateFactory: true, namespaced: true })
export default class PostsModule extends VuexModule {
  private static _namespace: BindingHelpers;

  public static get namespace(): BindingHelpers {
    if (this._namespace == null) this._namespace = getExamNamespace();

    return this._namespace;
  }

  public versions: Version[] = [];

  @Action
  public async getVersions({type, offset, clear}) {
    const res = await axios({
      method: "get",
      url: `/data/changelog_${type}_offset_${offset || 0}.json`
    });
    this.context.commit('setVersions', {data: res.data, clear});

    return res.data;
  }

  @Mutation
  public setVersions(value: {data: {count: number, rules: Version[]}, clear: boolean}) {
    if (value.clear) {
      this.versions = value.data.rules;
    } else {
      this.versions = [...this.versions, ...value.data.rules];
    }
  }
}
